<template>
	<div class="page">
		<div>
			<div class="box" v-if="status == 'success'">
				<img src="../../src/static/img/success.png" alt="" class="img">
				<div class="status">双录结束</div>
			</div>
			<div class="box" v-else>
				<img src="../../src/static/img/error.png" alt="" class="img">
				<div class="status">双录失败</div>
			</div>
			<van-button class="btn" type="info" size="large" @click="jump">返回业务平台（{{time}}s）</van-button>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				status:'',
				url:'',
				time:3,
				auto:null
			}
		},
		mounted() {
			this.status = this.$route.query.status
			let appId=JSON.parse(sessionStorage.getItem('userInfo')).appId
			let token=JSON.parse(sessionStorage.getItem('userInfo')).bizToken
			let secretKey=JSON.parse(sessionStorage.getItem('userInfo')).secretKey
			let dateTime=new Date().getTime()
			let params = {
				appId: appId,
				token: token,
				status: this.status,
				dateTime: dateTime
			}
			let sign=this.util.paramsFormat(params, secretKey)
			this.url = JSON.parse(sessionStorage.getItem('userInfo')).callbackLink +'?appId='+ appId +'&token='+token+'&status='+this.status+'&dateTime='+dateTime+'&sign='+sign
			this.autoJump()
		},
		destroyed() {
			clearInterval(this.auto)
		},
		methods:{
			jump(){
				window.location.href = this.url
			},
			autoJump(){
				let time = this.time
				let that = this
				this.auto = setInterval(()=>{
					if(time > 0){
						time = time - 1
						that.time = time
					}else if(time == 0){
						that.time = time
						clearInterval(that.auto)
						window.location.href = that.url
					}
				},1000)
			}
		}
	}
</script>

<style lang="less" scoped>
	.page{
		padding: 22px 24px;
	}
	.box{
		margin-bottom: 103px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.img{
			width: 274px;
		}
		.status{
			width: 100%;
			margin-top: 8px;
			font-size: 22px;
			color: #333;
			font-weight: bold;
			text-align: center;
		}
	}
	.btn{
		background-color: #5164FF;
		border-color: #5164FF;
		height: 44px;
		border-radius: 4px;
	}
</style>
